.box {
  background-color: $color-dark;
  border: 5px solid $color-white;
  width: 520px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 15px;
  z-index: 2;
}

.internalbox {
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
}

.contentbox {
  background-color: $color-dark;
  border: 5px solid $color-white;
  border-radius: 15px;
  position: relative;
  width: 720px;
  margin: 15px;
  margin-left: 15px;
  // height: 600px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  // justify-content: space-between;
  z-index: 2;
}


#aboutme{
  height: 400px;
  width: 1000px;
}

.card {
  background-color: $color-white;
  position:relative;
  height: 200px;
  width: 350px;
  text-align: center;
}

.triangle {
  border-style: solid;
  border-width: 0 550px 300px 0;
  border-color: transparent #fca8a0 transparent transparent;
  position: absolute; 
  top: 0px;
  right: 0px;
  opacity:25%;
}

.chessboard {
  border: 15px solid $color-dark;
  background: 
  repeating-conic-gradient(#769656 0% 25%, #EEEED2 0% 50%) 
    50% / 120px 120px;
  height: 480px;
  width: 480px;
}

.pieceholder {
  background-color: $color-offwhite;
  border: 15px solid $color-dark;
  position:relative;
  height: 120px;
  width: 480px;
}