@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic");

/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
  margin: 0;
  padding: 0;
}

html {
  margin: 0 !important;
  scroll-behavior: smooth;
}

body {
  color: $font-color;
  background-color: $color-gray;
  font-family: $font-family;
  font-size: $font-size-normal;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  size: inherit;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}