// FONTS
$font-family: "Rubik", sans-serif;

$font-size-smaller: 14px;
$font-size-normal: 16px;
$font-size-larger: 18px;
$font-size-x-large: 24px;
$font-size-xx-large: 28px;
$font-size-largest: 36px;

$font-color: #F2F3F4;

$font-bold: 700;
$font-normal: 400;

// COLORS
$color-gray: #6b6b6b;
$color-light: #00555A;
$color-dark: #032e58;
$color-white: #F2F3F4;
$color-offwhite: #FFE5B4;
$color-active: #F9B3A7;
$color-primary: #7bafd4;